import React from 'react';
import './privacypolicy.scss'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-top">
        <div className="privacy-heading">
          <h1>
            <i className="bi bi-hand-index-thumb"></i>Privacy Policy
          </h1>
          <span>Privacy Policy</span>
        </div>
        <div className="privacy-action">
          <div className="privacy-btn">
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add Policy</span>
          </div>
        </div>
      </div>
      <div className="privacy-body">
        <span>
          Not everyone knows how to make a Privacy Policy agreement, especially
          with CCPA or GDPR or CalOPPA or PIPEDA or Australia's Privacy Act
          provisions. If you are not a lawyer or someone who is familiar to
          Privacy Policies, you will be clueless. Some people might even take
          advantage of you because of this. Some people may even extort money
          from you. These are some examples that we want to stop from happening
          to you.
        </span>
        <span>
          We will help you protect yourself by generating a Privacy Policy.
        </span>
        <span>
          Our Privacy Policy Generator can help you make sure that your business
          complies with the law. We are here to help you protect your business,
          yourself and your customers.
        </span>
        <span>
          Fill in the blank spaces below and we will create a personalized
          website Privacy Policy for your business. No account registration
          required. Simply generate & download a Privacy Policy in seconds!
        </span>
        <span>
          Small remark when filling in this Privacy Policy generator: Not all
          parts of this Privacy Policy might be applicable to your website. When
          there are parts that are not applicable, these can be removed.
          Optional elements can be selected in step 2. The accuracy of the
          generated Privacy Policy on this website is not legally binding. Use
          at your own risk.
        </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy