// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recharts-legend-item-text {
  display: none;
}

.recharts-legend {
  padding: 0;
}

.recharts-tooltip {
  background-color: #a9cafd;
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/chart/chartmonthlyprogress.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,WAAA;AACF","sourcesContent":[".recharts-legend-item-text {\n  display: none;\n}\n\n.recharts-legend {\n  padding: 0;\n}\n\n.recharts-tooltip {\n  background-color: #a9cafd;\n  border-radius: 20px;\n  border: 1px solid #ccc;\n  padding: 15px;\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
