import React from 'react';
import './students.scss'
import { PiStudent } from "react-icons/pi";

const Students = ({ students }) => {
  return (
    <div className="students-container">
      <PiStudent className="icon" />
      <h3>Students</h3>
      <p>{students}</p>
    </div>
  );
};

export default Students