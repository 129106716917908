import React, { useState, useEffect } from "react";
import "./bestinstructors.scss";
import { GiTeacher } from "react-icons/gi";
import BestInstructorDetails from "./BestInstructorDetails";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const BestInstructors = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/admin/list/professors?collegeId=${currentUser.collegeId}`
        );
        setData(response.data);
        setError(null);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch data";
        setError(errorMessage);
      }
    };
    fetchData();
  }, [currentUser]);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="best-instructor-container">
      <h3>Instructors</h3>
      <GiTeacher className="icon" />
      <div className="best-instructor-details-container">
        {data.length > 0 ? (
          data.map((item, index) => (
            <BestInstructorDetails key={index} index={index} item={item} />
          ))
        ) : (
          <p>No data is available!</p>
        )}
      </div>
    </div>
  );
};

export default BestInstructors;
