import React from "react";
import "./bestinstructordetails.scss";

const BestInstructorDetails = ({ index, item }) => {
  const truncatedName =
    item.name.length > 12 ? item.name.slice(0, 12) + "..." : item.name;

  return (
    <div className="best-instructor-details">
      <p>#{index + 1}</p>
      <div className="instructor-icon">
        <i className="bi bi-person-fill"></i>
      </div>
      <div className="instructor-details">
        <p>{truncatedName}</p>
        <span>
          {item.role}: {item.specification}
        </span>
      </div>
    </div>
  );
};

export default BestInstructorDetails;
