// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.best-instructor-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  height: 80px;
}
.best-instructor-details p {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.best-instructor-details .instructor-icon {
  font-size: 40px;
  color: #a9cafd;
  height: 100%;
  display: flex;
  align-items: center;
}
.best-instructor-details .instructor-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.best-instructor-details .instructor-details p {
  margin: 0;
  color: #737373;
  font-size: 16px;
  font-weight: 700;
}
.best-instructor-details .instructor-details span {
  font-size: 14px;
  color: #737373;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/bestinstructordetails.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,SAAA;AACJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,QAAA;AADJ;AAGI;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADN;AAII;EACE,eAAA;EACA,cAAA;EACA,SAAA;AAFN","sourcesContent":[".best-instructor-details {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  width: 100%;\n  padding: 10px 20px;\n  border-radius: 10px;\n  height: 80px;\n\n  p {\n    font-size: 18px;\n    font-weight: 700;\n    margin: 0;\n  }\n\n  .instructor-icon {\n    font-size: 40px;\n    color: #a9cafd;\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n\n  .instructor-details {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 5px;\n\n    p {\n      margin: 0;\n      color: #737373;\n      font-size: 16px;\n      font-weight: 700;\n    }\n\n    span {\n      font-size: 14px;\n      color: #737373;\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
