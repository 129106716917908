import React, { useCallback, useEffect, useState } from "react";
import "./usermanagement.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import UserDetails from "../../components/user-management/UserDetails";
import SearchBar from "../../components/searchbar/SearchBar";
import { useSelector } from "react-redux";

const UserManagement = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [college, setCollege] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("student");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/names?collegeId=${currentUser.collegeId}`
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Unable to fetch courses!", error.message);
      }
    };
    fetchCourses();
  }, [currentUser]);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${SERVER_URL}/admin/list?collegeId=${currentUser.collegeId}`
      );
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      setError("Error loading data!");
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchUser();
    setEmail("");
    setCollege(currentUser.college)
    setRole("student");
    setSelectedCourse("");
  }, [fetchUser, currentUser]);

  const filterData = (searchTerm) => {
    const filtered = data.filter((item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearch = (searchTerm) => {
    filterData(searchTerm);
  };

  const handleAddClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const generateRandomPassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const autoGeneratedPassword =
      role === "professor" ? generateRandomPassword() : "";

    try {
      const postData = {
        name,
        college,
        role,
        specification: role === "professor" ? selectedCourse : "",
        email,
        password: autoGeneratedPassword,
      };

      const response = await axios.post(
        `${SERVER_URL}/admin/register`,
        postData
      );

      if (response.status === 200 || response.status === 201) {
        setData([...data, response.data]);
        setFilteredData([...data, response.data]);
        setEmail("");
        setShowPopup(false);
      }
    } catch (error) {
      setError("Error adding user!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-management-container">
      <div className="user-top">
        <div className="user-heading">
          <h1>
            <i className="bi bi-person"></i>User Management
          </h1>
          <span>User Management</span>
        </div>
        <div className="user-action">
          <SearchBar onSearch={handleSearch} />
          <div className="user-btn" onClick={handleAddClick}>
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add User</span>
          </div>
        </div>
      </div>
      <div className="user-details">
        <div className="user-details-top">
          <div className="user-details-top-sl">
            <span className="span-sl">SL No.</span>
          </div>
          <div className="user-details-top-email">
            <span className="span-email">Email</span>
          </div>
          <div className="user-details-top-role">
            <span className="span-role">Role</span>
          </div>
          <div className="user-details-top-action">
            <span className="span-action">Action</span>
          </div>
        </div>
        <div className="user-details-container">
          {loading && <p>Loading...</p>}
          {error && <p className="error">{error}</p>}
          {filteredData.map((row, index) => (
            <UserDetails
              row={row}
              index={index}
              key={index}
              refreshData={fetchUser}
            />
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <div className="user-popup-container">
              <h2>Add User</h2>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="form-group">
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                  >
                    <option value="student">Student</option>
                    <option value="professor">Professor</option>
                  </select>
                  <label htmlFor="role">Role</label>
                </div>

                {role === "professor" && (
                  <>
                    <div className="form-group">
                      <select
                        value={selectedCourse}
                        onChange={(e) => setSelectedCourse(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Course
                        </option>
                        {courses.map((course, index) => (
                          <option key={index} value={course}>
                            {course}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="course">Specification</label>
                    </div>
                  </>
                )}
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label htmlFor="name">Name</label>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>

                <div className="form-actions">
                  <button type="submit" disabled={loading}>
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
