import React, { useEffect, useState } from "react";
import "./courseeffectivenessanalysis.scss";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import CourseEffectivenessBumpChart from "./chart/CourseEffectivenessBumpChart";

const CourseEffectivenessAnalysis = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [courseEffectivenessData, setCourseEffectivenessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCourseEffectivenessData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/assignments/completed?collegeId=${currentUser.collegeId}`
        );
        setCourseEffectivenessData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchCourseEffectivenessData();
  }, [currentUser]);

  const isDataEmpty = courseEffectivenessData.every(
    (item) => item.data.length === 0
  );

  return (
    <div className="course-effectiveness-analysis-container">
      <h1>
        <FaMagnifyingGlassChart />
        Chapter effectiveness analysis
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {!loading && isDataEmpty && <p>No data available!</p>}
      {!isDataEmpty && (
        <CourseEffectivenessBumpChart data={courseEffectivenessData} />
      )}
    </div>
  );
};

export default CourseEffectivenessAnalysis;
