import React, { useEffect, useState } from "react";
import "./overallpasspercentage.scss";
import ChartOverallPassPercentage from "./chart/ChartOverallPassPercentage";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const OverallPassPercentage = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    const fetchOverAllPercentage = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/average-assignment-completion?collegeId=${currentUser.collegeId}`
        );
        setBarChartData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchOverAllPercentage();
  }, [currentUser]);

  return (
    <div className="overall-pass-percentage">
      <h3>Overall Pass Percentage</h3>
      <div className="overall-pass-percentage-chart-container">
        {barChartData.length > 0 ? (
          <ChartOverallPassPercentage data={barChartData} />
        ) : (
          <p>No data is available!</p>
        )}
      </div>
    </div>
  );
};

export default OverallPassPercentage;
