import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import Mentors from "../../components/dashboard/Mentors";
import Students from "../../components/dashboard/Students";
import CourseInProgress from "../../components/dashboard/CourseInProgress";
import AssignmentCompleted from "../../components/dashboard/AssignmentCompleted";
import TopPerformingCourses from "../../components/dashboard/TopPerformingCourses";
import BestInstructors from "../../components/dashboard/BestInstructors";
import CourseEffectivenessAnalysis from "../../components/dashboard/CourseEffectivenessAnalysis";
import OverallPassPercentage from "../../components/dashboard/OverallPassPercentage";
import { useSelector } from "react-redux";
import MonthlyProgress from "../../components/dashboard/MonthlyProgress";

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [mentors, setMentors] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalCourse, setTotalCourse] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [professorsResponse, studentsResponse, courseResponse] =
          await Promise.all([
            axios.get(
              `${SERVER_URL}/admin/count/professors?collegeId=${currentUser.collegeId}`
            ),
            axios.get(
              `${SERVER_URL}/admin/count/students?collegeId=${currentUser.collegeId}`
            ),
            axios.get(
              `${SERVER_URL}/user-analytics/distinct/courses?collegeId=${currentUser.collegeId}`
            ),
          ]);
        setMentors(professorsResponse.data.totalProfessors);
        setStudents(studentsResponse.data.totalStudents);
        setTotalCourse(courseResponse.data.distinctCourses);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-top">
        <div className="left-group">
          <div className="group-container">
            <Mentors mentors={mentors} />
            <Students students={students} />
          </div>
        </div>
        <hr className="divider" />
        <div className="right-group">
          <div className="group-container">
            <CourseInProgress totalCourse={totalCourse} />
            <AssignmentCompleted />
          </div>
        </div>
      </div>
      <div className="dashboard-middle">
        <TopPerformingCourses />
        <CourseEffectivenessAnalysis />
        <BestInstructors />
      </div>
      <div className="dashboard-bottom">
        <MonthlyProgress />
        <OverallPassPercentage />
      </div>
    </div>
  );
};

export default Dashboard;
