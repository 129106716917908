// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  line-height: 1;
  text-decoration: none;
}
.logo span {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  padding: 20px 15px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 25px;
  margin-left: 35px;
}
.logo span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px;
  padding: 2px;
  background: linear-gradient(90deg, #177AD6, #AF14EF);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
  .logo .toggle-sidebar-btn {
    visibility: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/logo/logo.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,oDAAA;EAIA,sEACE;EAGF,uBAAA;EACA,WAAA;AAJJ;;AAQA;EACE;IACE,YAAA;EALF;EAOE;IACE,kBAAA;EALJ;AACF","sourcesContent":[".logo {\n  line-height: 1;\n  text-decoration: none;\n\n  span {\n    font-size: 16px;\n    font-weight: 700;\n    color: #000;\n    text-transform: uppercase;\n    padding: 20px 15px;\n    position: relative;\n    z-index: 1;\n    background-color: #fff;\n    border-radius: 25px;\n    margin-left: 35px;\n  }\n\n  span::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border-radius: 25px;\n    padding: 2px;\n    background: linear-gradient(90deg, #177AD6, #AF14EF);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude;\n    z-index: -1;\n  }\n}\n\n@media (min-width: 1200px) {\n  .logo {\n    width: 280px;\n\n    .toggle-sidebar-btn{\n      visibility: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
