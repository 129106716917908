import React from "react";
import PrivacyPolicy from "../../pages/privacy-policy/PrivacyPolicy";

const PrivacyPolicyMain = () => {
  return (
    <main id="main" className="main">
      <PrivacyPolicy />
    </main>
  );
};

export default PrivacyPolicyMain;
