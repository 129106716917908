import React, { useEffect, useState } from "react";
import "./assignmentcompleted.scss";
import { GrGroup } from "react-icons/gr";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const AssignmentCompleted = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [assignment, setAssignment] = useState(0);

  useEffect(() => {
    const fetchTotalAssignmentCompleted = async () => {
      const response = await axios.get(
        `${SERVER_URL}/user-analytics/total-assignment-completed?collegeId=${currentUser.collegeId}`
      );
      setAssignment(response.data.total);
    };
    fetchTotalAssignmentCompleted();
  }, [currentUser]);

  return (
    <div className="assignment-completed-container">
      <GrGroup className="icon" />
      <h3>Assignment Completed</h3>
      <p>{assignment}</p>
    </div>
  );
};

export default AssignmentCompleted;
