// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors-container {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.mentors-container .icon {
  width: 60px;
  height: 60px;
}
.mentors-container h3 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  background: #fff;
  color: #000;
  padding: 5px 25px;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.mentors-container h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(90deg, #0000ff, #ff22e6);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}
.mentors-container p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  color: #8c52ff;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/mentors.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,4CAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AAAJ;AAEI;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,oDAAA;EACA,8EAAA;EAGA,uBAAA;EACA,WAAA;AADN;AAKE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAHJ","sourcesContent":[".mentors-container {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  background: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n\n  .icon {\n    width: 60px;\n    height: 60px;\n  }\n\n  h3 {\n    font-size: 16px;\n    font-weight: 700;\n    text-transform: uppercase;\n    margin-bottom: 0;\n    background: #fff;\n    color: #000;\n    padding: 5px 25px;\n    border-radius: 20px;\n    position: relative;\n    z-index: 1;\n\n    &::before {\n      content: \"\";\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      border-radius: 20px;\n      padding: 2px;\n      background: linear-gradient(90deg, #0000ff, #ff22e6);\n      -webkit-mask: linear-gradient(#fff 0 0) content-box,\n        linear-gradient(#fff 0 0);\n      -webkit-mask-composite: xor;\n      mask-composite: exclude;\n      z-index: -1;\n    }\n  }\n\n  p {\n    margin-bottom: 0;\n    font-size: 20px;\n    font-weight: 700;\n    color: #8c52ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
