import React, { useEffect, useState } from "react";
import "./topperformingcourses.scss";
import ChartTopPerformingCourses from "./chart/ChartTopPerformingCourses";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { IoBarChartSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

const TopPerformingCourses = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

   useEffect(() => {
     const fetchData = async () => {
       setLoading(true);
       setError("");
       try {
         const response = await axios.get(
           `${SERVER_URL}/user-analytics/top-performing-courses?collegeId=${currentUser.collegeId}`
         );

         const uniqueData = response.data.map((serie) => {
           const seen = new Set();
           const unique = serie.data.filter((item) => {
             if (seen.has(item.x)) {
               return false;
             }
             seen.add(item.x);
             return true;
           });

           return {
             ...serie,
             data: unique,
           };
         });

         setData(uniqueData);
       } catch (error) {
         setError("Error loading data!");
       } finally {
         setLoading(false);
       }
     };
     fetchData();
   }, [currentUser]);

  return (
    <div className="barchart-container">
      <h1>
        <IoBarChartSharp />
        Top Performing Chapters
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {data.length > 0 ? (
        <ChartTopPerformingCourses data={data} />
      ) : (
        <p>No data is available!</p>
      )}
    </div>
  );
};

export default TopPerformingCourses;
