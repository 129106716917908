import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./chartmonthlyprogress.scss";

const ChartMonthlyProgress = ({ data }) => {
  const monthMap = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  const transformedData = data.map((item) => {
    const fullMonth = item.Month.slice(0, -2);
    const yearPart = item.Month.slice(-2);
    const shortMonth = monthMap[fullMonth] || fullMonth.slice(0, 3);
    return {
      ...item,
      Month: `${shortMonth}${yearPart}`,
    };
  });

  return (
    <ResponsiveContainer width="100%" height={360}>
      <AreaChart
        data={transformedData}
        margin={{ top: 20, right: 20, left: 20, bottom: 30 }}
      >
        <defs>
          <linearGradient id="color3D" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="Month"
          label={{
            value: "Month",
            position: "insideBottom",
            offset: -10,
            dy: 10,
            style: { fontSize: "12px", color: "#000" },
          }}
        />
        <YAxis
          label={{
            value: "Chapters Completed",
            angle: -90,
            position: "Left",
            offset: 30,
            dx: -30,
            dy: 10,
            style: { fontSize: "12px", color: "#000", marginTop: "10px" },
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomizedLegend />} />
        <Area
          type="monotone"
          dataKey="chaptersCompleted"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#color3D)"
          strokeWidth={3}
          style={{
            filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4))",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#a9cafd",
          borderRadius: "20px",
          border: "1px solid #ccc",
          padding: "15px",
          color: "#000",
        }}
      >
        <p>
          <strong>Month:</strong> {label}
        </p>
        <p>
          <strong>Chapters Completed:</strong> {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const CustomizedLegend = () => {
  return <div style={{ display: "none" }} />;
};

export default ChartMonthlyProgress;
