import React from "react";
import "./courseinprogress.scss";
import { RiProgress5Line } from "react-icons/ri";

const CourseInProgress = ({ totalCourse }) => {
  return (
    <div className="course-in-progress-container">
      <RiProgress5Line className="icon" />
      <h3>Course in progress</h3>
      <p>{totalCourse}</p>
    </div>
  );
};

export default CourseInProgress;
