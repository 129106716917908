import React, { useState } from "react";
import "./login.scss";
import logo from "../../assets/valtep-logo.png";
import PasswordInput from "../../components/password-input/PasswordInput";
import EmailInput from "../../components/email-input/EmailInput";
import { FaUserCircle } from "react-icons/fa";
import Cookies from "js-cookie";
import axios from "axios";
import { SERVER_URL } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      dispatch(signInStart());

      const res = await axios.post(`${SERVER_URL}/admin/login`, {
        email: formData.email,
        password: formData.password,
      });

      const data = res.data;

      if (data.role !== "admin") {
        throw new Error("Unauthorized access! You are not an Admin!");
      }

      if (res.status === 200) {
        dispatch(signInSuccess(data));
        Cookies.set("access_token", data._id);
        setError(null);
        setLoading(false);
        navigate("/dashboard");
      } else {
        throw new Error(data.message || "Login failed");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || "An error occurred");
      } else {
        setError(error.message);
      }
      dispatch(signInFailure(error.message));
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="left-group"></div>
      <div className="right-group">
        <div className="login-form-container">
          <img src={logo} alt="valtep-logo" />
          <form method="POST" onSubmit={handleSubmit}>
            <FaUserCircle className="icon" />
            <EmailInput
              name="email"
              id="email"
              type="email"
              placeholder="Email"
              autoComplete="on"
              value={formData.email}
              onChange={handleChange}
            />
            <PasswordInput
              name="password"
              id="password"
              placeholder="Password"
              autoComplete="on"
              value={formData.otp}
              onChange={handleChange}
            />
            <button className="btn-login" type="submit">
              {loading ? "Logging in..." : "Login"}
            </button>
            {error && <p style={{ color: "#ff0000" }}>{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
