import React, { useEffect, useState } from "react";
import "./monthlyprogress.scss";
import ChartMonthlyProgress from "./chart/ChartMonthlyProgress";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const MonthlyProgress = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [areaChartData, setAreaChartData] = useState([]);

  useEffect(() => {
    const fetchMonthlyProgress=async()=>{
      const response = await axios.get(
        `${SERVER_URL}/user-analytics/chapter-started/count?collegeId=${currentUser.collegeId}`
      );
      setAreaChartData(response.data);
    }
    fetchMonthlyProgress();
  }, [currentUser]);

  return (
    <div className="monthly-progress-container">
      <h3>Monthly Progress</h3>
      <div className="monthly-progress-chart-container">
        <ChartMonthlyProgress data={areaChartData} />
      </div>
    </div>
  );
};

export default MonthlyProgress;
