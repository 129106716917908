import React, { useState, useEffect } from "react";
import "./userdetails.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const UserDetails = ({ row, index, refreshData }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [name, setName] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [role, setRole] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/names?collegeId=${currentUser.collegeId}`
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Unable to fetch courses!", error.message);
      }
    };

    fetchCourses();
  }, [currentUser.collegeId]);

  useEffect(() => {
    if (modalMode === "edit") {
      setEditedEmail(row.email);
      setSelectedName(row.name);
      setRole(row.role);
      if (row.role === "professor") {
        setSelectedCourse(row.specification || "");
      }
    }
  }, [modalMode, row]);

  const handleEditClick = () => {
    if (row.email) {
      setModalMode("edit");
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (row.email) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditedEmail("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: name,
        role: role,
        email: editedEmail,
        specification: role === "professor" ? selectedCourse : "",
      };
      let response;
      if (modalMode === "edit") {
        response = await axios.put(
          `${SERVER_URL}/admin/${row._id}/update?collegeId=${currentUser.collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${SERVER_URL}/admin/add?collegeId=${currentUser.collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/admin/${row._id}/delete?collegeId=${currentUser.collegeId}`
      );
      if (response.status === 200) {
        console.log("Delete confirmed");
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="user-details-row" key={row._id}>
      <div className="user-details-row-sl">
        <span>{index + 1}.</span>
      </div>
      <div className="user-details-row-email">
        <span>{row.email}</span>
      </div>
      <div className="user-details-row-role">
        <span>{row.role}</span>
      </div>
      <div className="user-details-row-action">
        <div className="user-row-button-wrapper">
          <div
            className={`button ${row.role === "admin" ? "disabled" : ""}`}
            onClick={row.role !== "admin" ? handleEditClick : null}
          >
            <div className="icon">
              <i className="bi bi-pencil-square"></i>
            </div>
            <span>Edit</span>
          </div>
          <div
            className={`button ${row.role === "admin" ? "disabled" : ""}`}
            onClick={row.role !== "admin" ? handleDeleteClick : null}
          >
            <div className="icon">
              <i className="bi bi-trash"></i>
            </div>
            <span>Delete</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="edit-user-popup-container">
              <h2>{modalMode === "edit" ? "Edit User" : "Add User"}</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="professor">Professor</option>
                    <option value="student">Student</option>
                  </select>
                  <label htmlFor="role">Role</label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                {role === "professor" && (
                  <div className="form-group">
                    <select
                      value={selectedCourse}
                      onChange={(e) => setSelectedCourse(e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        Select Course
                      </option>
                      {courses.map((course, index) => (
                        <option key={index} value={course}>
                          {course}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="course">Specification</label>
                  </div>
                )}
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={selectedName !== "" ? selectedName : name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label htmlFor="name">Name</label>
                </div>
                <div className="form-actions">
                  <button type="submit">
                    {modalMode === "edit" ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this user?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
