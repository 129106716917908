import React from "react";
import "./mentors.scss";
import { FaChalkboardTeacher } from "react-icons/fa";

const Mentors = ({ mentors }) => {
  return (
    <div className="mentors-container">
      <FaChalkboardTeacher className="icon" />
      <h3>Mentors</h3>
      <p>{mentors}</p>
    </div>
  );
};

export default Mentors;
