import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";

const colors = ["#ff6384", "#36a2eb", "#cc65fe", "#ffce56"];

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  const barHeight = height * 0.7;
  const barTop = y + (height - barHeight);

  return (
    <g>
      <rect
        x={x + 3}
        y={y + 3 + (height - barHeight)}
        width={width}
        height={barHeight}
        fill="#000"
        opacity={0.3}
      />
      <rect
        x={x}
        y={barTop}
        width={width}
        height={barHeight}
        fill={fill}
        style={{
          filter: "drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.5))",
        }}
      />
      <polygon
        points={`${x},${barTop} ${x + width},${barTop} ${x + width - 8},${
          barTop - 8
        } ${x + 8},${barTop - 8}`}
        fill={fill}
        fillOpacity="0.7"
      />
      <polygon
        points={`${x + width},${barTop} ${x + width},${barTop + barHeight} ${
          x + width - 8
        },${barTop + barHeight - 8} ${x + 8},${barTop - 8}`}
        fill={fill}
        fillOpacity="0.5"
      />
    </g>
  );
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#a9cafd",
          color: "#000",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "20px",
          border: "1px solid #ccc",
          padding: "15px",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
        <p
          style={{ margin: 0 }}
        >{`Assignment Completion: ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

const ChartOverallPassPercentage = ({ data }) => {
  const chartData = data.map((item, index) => ({
    chapter: item.chapter,
    averageAssignmentCompletion: item.averageAssignmentCompletion,
    color: colors[index % colors.length],
  }));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ResponsiveContainer width="100%" height={360}>
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 50,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="chapter"
            label={{
              value: "Chapter Name",
              position: "insideBottom",
              offset: -10,
            }}
          />
          <YAxis
            label={{
              value: "Average Assignment Completion %",
              angle: -90,
              position: "insideLeft",
              offset: 10,
              style: {
                textAnchor: "middle",
              },
            }}
            domain={[0, 100]}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="averageAssignmentCompletion"
            name="Assignment Percentage"
            shape={<CustomBar />}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartOverallPassPercentage;
