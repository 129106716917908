import React from "react";
import { ResponsiveLine } from "@nivo/line";

const CourseEffectivenessBumpChart = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No valid data available</div>;
  }

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: "every 1",
          orient: "bottom",
          legend: "Chapter Name",
          legendOffset: 44,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Assignment Percentage",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "nivo" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        tooltip={({ point }) => (
          <div
            style={{
              backgroundColor: "#a9cafd",
              borderRadius: "20px",
              border: "1px solid #ccc",
              padding: "15px",
            }}
          >
            <strong>{point.serieId}</strong>
            <br />
            Assignment Percentage: {point.data.y}%<br />
            Chapter: {point.data.x}
          </div>
        )}
      />
    </div>
  );
};

export default CourseEffectivenessBumpChart;
